import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import SectionBase from "../components/sections/base"
//import SectionGrid3 from "../components/sections/grid/grid3"
import { Title } from "../components/atoms/titles"
import { Html } from "../components/atoms/text"
import FormRide from "../components/forms/ride"
import SectionGrid from "../components/sections/grid/grid"
import HeroInner2 from "../components/sections/hero/inner2"
import useTestRides from "../hooks/useTestRides"
import { Link } from "gatsby"
import Agent from "../translations/agent"


const IndexPage = ({ data: { node } }) => {

  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1 data={node.relationships.info} />
      <Section2 data={node.relationships.info} />
      <Section3 />
    </Layout>
  )
}

const Hero = ({ data }) => {

  const { title, subtitle } = data
  const url = data.relationships.image.localFile.publicURL

  //const action1 = data.relationships.actions[0]
  //const action2 = data.relationships.actions[1]

  let backgrounds = []
  backgrounds = data.relationships.images.map( (m) => ( m.localFile.publicURL  ))
  if( backgrounds.length === 0 ){
    backgrounds.push(url)
  }

  return (
    <HeroInner2
      title={title}
      style={{ backgroundImage: `url("${url}")` }}
      description={subtitle}
      action1={false}
      backgrounds={backgrounds}
    />
  )
}


const Section1 = ({ data }) => {

  const { title } = data

  const html1 = data?.description[0]?.processed
  const html2 = data?.description[1]?.processed
  return (
    <>
      <SectionGrid
        className="py-12 lg:py-24 px-4 lg:px-44"
        classNameGrid="grid-cols-1 lg:grid-cols-[2fr_3fr] gap-12"
      >
        <div className="lg:space-y-12">
          <Title className="text-3xl lg:text-[50px] font-bold">{title}</Title>
          <Html className="text-lg lg:text-[25px] font-light	">{html1}</Html>

        </div>
        <div >
          <div className="text-center mb-12">
          <Link className="border border-black p-1 px-5 text-2xl   lg:text-3xl font-bold mb-4 inline-block" to="/test-ride#register">להרשם</Link>
          </div>
          <Html className="text-lg lg:text-lg">{html2}</Html>

        </div>
      </SectionGrid>


    </>
  )

}

const Section2 = ({ data }) => {

  //console.log(data);SectionTopCategories

  const url = data.relationships.image.localFile.publicURL
  return (
    <SectionBase
      className="h-[250px] lg:h-[500px]"
      style={{ backgroundImage: `url("${url}")` }}
    />
  )
}


const Section3 = () => {

  const items = useTestRides()
  //console.log(items)

  const rows = items.map((item, index) => (
    <div className="flex flex-col  h-full opacity-90 " key={index}>
      <div className="text-base">{item.date}</div>
      <div className="py-2">
        <div className="text-2xl font-bold">{item.title}</div>
        <div>{item.place}</div>
      </div>
      <div className="text-base">
        <div>{item.hours}</div>
        <div>{item.description}</div>
      </div>
    </div>
  ))

  const first = [rows[0]]
  const last = [rows[1]]

  return (
    <section className="py-12 bg-dark text-white ">
      <div className="container " id="register">
        <div
          className="py-0  flex flex-col lg:flex-row justify-around space-y-12 lg:space-y-0 text-center lg:text-right"
          //classNameGrid="  "
        >

          <div className="space-y-6 ">
            <Title className='text-3xl lg:text-4xl   font-bold lg:font-normal'>
              {Agent.translate('riding evenet')}
            </Title>
            <div className=" grid lg:grid-cols-2 gap-8">{first}</div>
          </div>

          <div className="space-y-6 ">
            <Title className='text-3xl lg:text-4xl   font-bold lg:font-normal'>
              {Agent.translate('date trike')}
            </Title>
            <div className=" grid lg:grid-cols-2 gap-8">{last}</div>
          </div>

          <div className="space-y-8 lg:min-w-[350px]">
            <Title className='text-3xl lg:text-4xl font-bold lg:font-normal '>
              {Agent.translate('lets do trick date')}
            </Title>
            <FormRide className="text-primary bg-transparent max-w-[350px]" rides={items} />
          </div>

        </div>
      </div>
    </section>
  )

}

export default IndexPage

export const query = graphql`
  query testride($id: String!) {
    node: nodeTestRides(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path{
        alias
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        info: field_paragraph_block {
          ...BlockContent
        }
      }
    }
  }
`
