import { graphql, useStaticQuery } from "gatsby"

const useTestRides = () => {

  const data = useStaticQuery(graphql`
    query {
      rides: allNodeTestRide {
        nodes {
          title
          id
          nid: drupal_internal__nid
          place: field_place
          hours: field_hours
          description: field_description
          date: field_date(formatString: "D/M/Y")
        }
      }
    }
  `)

  return data.rides.nodes

}

export default useTestRides
